// 导入Vue和VueRouter
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '@/view/HomePage.vue';
import PcPage from '@/view/DownloadPc.vue';
import MobilePage from '@/view/DownloadPhone.vue';
import WatchPage from '@/view/DownloadWatch.vue';
import RobotPage from '@/view/DownloadRobot.vue';
Vue.use(VueRouter)

// 创建一个路由器实例
const router = new VueRouter({
    mode: 'history', // 使用HTML5 History模式
    routes: [
        // 定义路由
        { path: '/', component: HomePage },
        { path: '/pc', component: PcPage },
        { path: '/mobile', component: MobilePage },
        { path: '/watch', component: WatchPage },
        { path: '/robot', component: RobotPage },
    ]
});

export default router;