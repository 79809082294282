<template>
  <div class="eighthComponent">
<!--    <div class="bottom-logo">-->
      <div class="word-div">
        <span>© {{$t('message.Chinese-Visual-Keyboard')}} &nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span v-if="this.$i18n.locale=== 'en' "><a href="/policyEn.html">Privacy Policy</a>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span v-else><a href="/policyCn.html">隐私政策</a>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span v-if="this.$i18n.locale=== 'en' "><a href="/termsEn.html">Terms & Conditions</a></span>
        <span v-else><a href="/termsCn.html">通用服务与条款</a>&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </div>
      <div class="logo-div" style="height: 100%">
        <div class="logo-img" style="height: 100%;display: flex;flex-direction: column;justify-content: center"><img src="@/assets/logo.png" style="width: 22px;height: 22px" alt=""></div>
        <div class="logo-section" style="height: 100%;display: flex;flex-direction: column;justify-content: center">&nbsp;&nbsp;{{$t('message.Chinese-Visual-Keyboard')}}</div>
      </div>
<!--    </div>-->
  </div>
</template>

<style scoped>
a{
  text-decoration: none;
  color: black;
  font-family: Helvetica;
}
</style>
<script>
import '@/style/eighthComponent.css'

export default {}
</script>