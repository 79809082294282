<template>
  <div class="image-link">
    <div class="image-container" @click="handleClick">
      <img src="@/assets/Rad_205.png" alt="Example Image" class="image" style="height: 3.5rem;width: 12.25rem">
      <div class="text-overlay">{{ txt }}</div>
    </div>
  </div>
</template>

<script>
import {Message} from "element-ui";
import i18n from "@/i18n.js";
export default {
  name: 'ImageWithText',
  props: {
    link: {
      type: String,
      required: true
    },
    txt: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    }
  },
  methods: {
    validateEmail() {
      // 邮箱的正则表达式
      const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      // 检验邮箱是否有效
      var emailError = !re.test(this.email);
      if (emailError) {
        Message.error('邮箱格式不正确');
        return false;
      }else {
        return true;
      }
    },
    handleClick() { //暂时用Ajax
      // alert(`email: ${this.email}`)
      const sourceData = {
        "content": `${this.message}`,
        "email": `${this.email}`,
        "fullName": `${this.name}`
      };
     if  (!this.validateEmail()){
       return
     }
      const url = process.env.VUE_APP_API_URL + '/domain/contactUs'
      var xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xhr.setRequestHeader("appToken", "unknow");
      xhr.setRequestHeader("languageCode", "zh-CN");
      xhr.setRequestHeader("appType", "domain");
      xhr.setRequestHeader("appVersion", "1.0.0");
      xhr.setRequestHeader("sysType", "1");
      xhr.setRequestHeader("userMark", "today");
      xhr.setRequestHeader("appIp", "localhost");

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          let response = xhr.response
          let res = JSON.parse(response)
          console.log(res)
          if (res.code === 0) {
            Message({
              type: "info",
              message: i18n.t('message.commit_success')
            })
          } else {
            Message({
              type: "error",
              message: i18n.t('message.commit_failure')
            })
          }
        } else {
          // alert('commit failed')
        }
      };
      let data = JSON.stringify(sourceData)
      xhr.send(data)
    }
  }
}
</script>

<style scoped>
.image-link {
  display: inline-block;
  text-decoration: none; /* 移除链接下划线 */
}

.image-container {
  position: relative;
  display: inline-block; /* 确保图片容器的宽度与图片一致 */
  cursor: pointer; /* 鼠标指针变成手形 */
}

.image {
  display: block; /* 避免图片下方出现间隙 */
  width: 80%; /* 让图片适应容器宽度 */
  height: auto; /* 保持图片比例 */
}

.text-overlay {
  position: absolute;
  width: 60%;
  top: 50%;
  left: 40%;
  transform: translate(-40%, -50%); /* 使用transform实现精确居中 */
  color: white; /* 设置文字颜色 */
  font-size: 14px; /* 使用响应式单位 */
  padding: 1vw; /* 设置响应式内边距 */
  border-radius: 0.5vw; /* 设置响应式圆角 */
  text-align: center; /* 居中文字 */
}

@media screen and (max-width: 1080px) {
  .text-overlay {
    position: absolute;
    width: 60%;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%); /* 使用transform实现精确居中 */
    color: white; /* 设置文字颜色 */
    font-size: 4vw; /* 使用响应式单位 */
    padding: 1vw; /* 设置响应式内边距 */
    border-radius: 0.5vw; /* 设置响应式圆角 */
    text-align: center; /* 居中文字 */
  }
}
</style>
