<template>
  <div class="photoContainer">
    <div v-if="this.$i18n.locale==='zh'">
      <div class="download-photo-div"><img src="../../assets/robot.png" alt="" class="download-photo" style="width: 264px;height: 320px"></div>
      <div class="title-txt1"><h4>机器人终端</h4></div>
      <div class="resolution-info">
        <span>&nbsp;&nbsp;&nbsp;基于汉字构件和笔画系统重构,优化机器人交互体验的中文输入法。</span>
      </div>
      <br>
      <div class="download-href"><a href="javascript:void(0)" @click="gotoDownload"> 了解更多</a></div>
    </div>
<!--    -->
    <div v-if="this.$i18n.locale==='zh_tw'">
      <div class="download-photo-div"><img src="../../assets/robot.png" alt="" class="download-photo" style="width: 264px;height: 320px"></div>
      <div class="title-txt1"><h4>機器人終端</h4></div>
      <div class="resolution-info">
        <span>&nbsp;&nbsp;&nbsp;基於漢字構件和筆劃系統重構,優化機器人互動體驗的中文輸入法。</span>
      </div>
      <br>
      <div class="download-href"><a href="javascript:void(0)" @click="gotoDownload"> 瞭解更多</a></div>
    </div>
<!--    -->
    <div v-if="this.$i18n.locale==='en'">
      <div class="download-photo-div"><img src="../../assets/robot.png" alt="" class="download-photo" style="width: 264px;height: 320px"></div>
      <div class="title-txt1"><h3>Robot</h3></div>
      <div class="resolution-info">
        <span style="height: 6rem">Based on the reconstruction of Chinese character components and stroke system, the Chinese input method for robot interactive experience is optimized.</span>
        <br>
      </div>
      <br><br>
      <div class="download-href" ><a href="javascript:void(0)" @click="gotoDownload">more</a></div>
    </div>
  </div>
</template>
<script>
export default {
  name:'RobotComponent',
  methods:{
    gotoDownload(){
      this.$router.push('/robot').then(()=>{
        window.scroll(0,0) //scroll to top
      })
    }
  }
}
</script>
<style scoped>
.download-a{
  color: gray;
  font-family: Helvetica;
}
.download-photo-div{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.download-photo{
  //width: 70%;
  //height: 91%;
  width: 100%;
  height: 100%;
  color: gray;
}
.photoContainer{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 18%;
  height: 18%;
}
.title-txt1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: Helvetica;
  font-size: 1.125rem;
}
.resolution-info{
  color: #9498ab;
  display: flex;
  /*height: 5rem;*/
  letter-spacing: 0.02vw;
  line-height:2.5vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size:0.875rem ;
  font-family: Helvetica;
  text-align: center;
}
.download-href{
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: Helvetica;
  font-size:0.875rem ;
  line-height:2.5rem
}
.download-href a{
  color: #1e0101;
}
@media screen and (max-width: 1080px) {
  .photoContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 1280px) and (min-width:1080px) {
  .resolution-info{
    color: #9498ab;
    display: flex;
    /*height: 5rem;*/
    letter-spacing: 0.02vw;
    line-height:20px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size:0.875rem ;
    font-family: Helvetica;
  }
}
</style>
