var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"photoContainer"},[(this.$i18n.locale==='zh')?_c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_c('br'),_c('div',{staticClass:"download-href"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.gotoDownload}},[_vm._v(" 了解更多")])])]):_vm._e(),(this.$i18n.locale==='zh_tw')?_c('div',[_vm._m(3),_vm._m(4),_vm._m(5),_c('br'),_c('div',{staticClass:"download-href"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.gotoDownload}},[_vm._v(" 瞭解更多")])])]):_vm._e(),(this.$i18n.locale==='en')?_c('div',[_vm._m(6),_vm._m(7),_vm._m(8),_c('br'),_c('br'),_c('div',{staticClass:"download-href"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.gotoDownload}},[_vm._v("more")])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-photo-div"},[_c('img',{staticClass:"download-photo",staticStyle:{"width":"264px","height":"320px"},attrs:{"src":require("../../assets/robot.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-txt1"},[_c('h4',[_vm._v("机器人终端")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolution-info"},[_c('span',[_vm._v("   基于汉字构件和笔画系统重构,优化机器人交互体验的中文输入法。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-photo-div"},[_c('img',{staticClass:"download-photo",staticStyle:{"width":"264px","height":"320px"},attrs:{"src":require("../../assets/robot.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-txt1"},[_c('h4',[_vm._v("機器人終端")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolution-info"},[_c('span',[_vm._v("   基於漢字構件和筆劃系統重構,優化機器人互動體驗的中文輸入法。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-photo-div"},[_c('img',{staticClass:"download-photo",staticStyle:{"width":"264px","height":"320px"},attrs:{"src":require("../../assets/robot.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-txt1"},[_c('h3',[_vm._v("Robot")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolution-info"},[_c('span',{staticStyle:{"height":"6rem"}},[_vm._v("Based on the reconstruction of Chinese character components and stroke system, the Chinese input method for robot interactive experience is optimized.")]),_c('br')])
}]

export { render, staticRenderFns }