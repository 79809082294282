<template>
  <div class="first-container" id="section1">
    <br><br><br><br><br><br><br><br><br><br><br>
    <div class="video-section">
      <chinese-component @play-video="handlePlayVideo"></chinese-component>
      <video-component ref="cComponent"></video-component>
    </div>
    <br><br><br><br><br><br>
  </div>
</template>
<script>
import '@/style/firstComponent.css';
import ChineseComponent from "@/components/FirstComponent/ChineseComponent.vue";
import VideoComponent from "@/components/FirstComponent/VideoComponent.vue";
import {EventBus} from "@/eventBus/eventBus";

export default {
  name: 'FirstComponent',
  components: {
    ChineseComponent,
    VideoComponent
  },
  methods:{
    handlePlayVideo(){
      this.$refs.cComponent.playVideo();
    },
    handleScrollToSection(sectionId) {
      const section = document.getElementById(sectionId)
      if (section) {
        section.scrollIntoView({behavior: 'smooth'});
      }
    }
  },
  mounted() {
    EventBus.$on('scrollToSection', this.handleScrollToSection);
  },
  beforeDestroy() {
    EventBus.$off('scrollToSection', this.handleScrollToSection);
  },
}
</script>

<style scoped>

</style>