import Vuex from 'vuex'
import Vue from "vue";

Vue.use(Vuex)
const state = {
    isChinese: true,
    isDownloaded: 'home',
};
const mutations = {
    changeLanguage(state, value) {
        state.isChinese = value
        // console.log("state.isChinese:",state.isChinese )
    },
    gotoPage(state, value) {
        state.isDownloaded = value
    },
};
const actions = {};
export default new Vuex.Store({
    state,
    mutations,
    actions
})
