import { render, staticRenderFns } from "./FirstComponent.vue?vue&type=template&id=2ac5c3ec&scoped=true"
import script from "./FirstComponent.vue?vue&type=script&lang=js"
export * from "./FirstComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ac5c3ec",
  null
  
)

export default component.exports