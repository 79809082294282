var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"photoContainer"},[(this.$i18n.locale==='zh')?_c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_c('br'),_c('div',{staticClass:"download-href"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.gotoDownload}},[_vm._v("了解更多")])]),_vm._v("   ")]):_vm._e(),(this.$i18n.locale==='zh_tw')?_c('div',[_vm._m(3),_vm._m(4),_vm._m(5),_c('br'),_c('div',{staticClass:"download-href"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.gotoDownload}},[_vm._v("瞭解更多")])]),_vm._v("   ")]):_vm._e(),(this.$i18n.locale==='en')?_c('div',[_vm._m(6),_vm._m(7),_vm._m(8),_c('br'),_vm._v(" "),_c('br'),_c('div',{staticClass:"download-href"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.gotoDownload}},[_vm._v("more")])]),_vm._v("   ")]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-photo-div"},[_c('img',{staticClass:"download-photo",staticStyle:{"width":"264px","height":"320px"},attrs:{"src":require("../../assets/phone.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-txt1"},[_c('h4',[_vm._v("移动端")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolution-info"},[_c('span',[_vm._v("   结合汉字构件和笔画,简化输入步骤，提升打字速度的智能输入法。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-photo-div"},[_c('img',{staticClass:"download-photo",staticStyle:{"width":"264px","height":"320px"},attrs:{"src":require("../../assets/phone.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-txt1"},[_c('h4',[_vm._v("移動端")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolution-info"},[_c('span',[_vm._v("   結合漢字構件和筆劃,簡化輸入步驟，提升打字速度的智慧輸入法。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-photo-div"},[_c('img',{staticClass:"download-photo",staticStyle:{"width":"264px","height":"320px"},attrs:{"src":require("../../assets/phone.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-txt1"},[_c('h3',[_vm._v("Mobile")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolution-info"},[_c('span',{staticStyle:{"height":"6rem"}},[_vm._v("An intelligent input method that combines Chinese character components and strokes to simplify input steps and improve typing speed.")]),_c('br')])
}]

export { render, staticRenderFns }