<template>
  <div class="sixth-container" id="section6">
    <div class="left-section">
      <img src="../../assets/dr_chu.png" alt="" class="chu_img" >
    </div>
    <div v-if="$store.state.isChinese" class="right-section">
      <div class="founder-section">
        <section class="name-section">{{$t('message.founder')}}</section>
        <section class="detail1-section">{{$t('message.detail1-section')}}</section>
        <section class="detail2-section">{{$t('message.detail2-section')}}</section>
        <section class="detail3-section">{{$t('message.detail3-section')}}</section>
        <section class="detail4-section">{{$t('message.detail4-section')}}</section>
        <section class="detail5-section">{{$t('message.detail5-section')}}</section>
      </div>
    </div>
  </div>
</template>
<script>
import {EventBus} from "@/eventBus/eventBus";

export default {
  data() {
    return {}
  },
  methods:{
    handleScrollToSection(sectionId) {
      const section = document.getElementById(sectionId)
      if (section) {
        section.scrollIntoView({behavior: 'smooth'});
      }
    }
  },
  mounted() {
    EventBus.$on('scrollToSection', this.handleScrollToSection);
  },
  beforeDestroy() {
    EventBus.$off('scrollToSection', this.handleScrollToSection);
  },
}
</script>
<style scoped>
#section6{
  border-top: 0.2rem solid #f6f6f6;;
}
.chu_img {
  width: 26.25rem;
  height: 26.25rem;
}

.sixth-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //border-bottom: 0.1rem solid #f6f6f6;
  //border-top: 0.1rem solid #f6f6f6;
}

.left-section {
  background: rgb(237, 237, 237);
  width: 50%;
  aspect-ratio: 1 / 1;      /* 设置宽高比为1:1，即高度等于宽度 */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.detail-section{
  font-size: 1rem;
  line-height: 1.625rem;
}
.right-section {
  width: 50%;
  aspect-ratio: 1 / 1;      /* 设置宽高比为1:1，即高度等于宽度 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Helvetica;
  line-height: 2rem;
  font-size: 1rem;
}
.name-section{
  font-size: 1.5rem;
  margin-bottom: 24px
}
.founder-section{
  display: flex;
  flex-direction:column;
  justify-content: center;
}
@media screen and (max-width: 1080px) {
  .sixth-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .left-section {
    background: rgb(237, 237, 237);
    width: 40.5%;
    height: 20vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .name-section{
    font-size: medium;
  }
  .detail-section{
    font-size: xx-small;
  }
}
</style>