<template>
  <div class="image-link">
    <div class="image-container">
      <img src="@/assets/Rad_205.png" alt="Example Image" class="image">
      <div class="text-overlay"><a :href=link>{{ txt }}</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageWithText',
  props: {
    link: {
      type: String,
      required: true
    },
    txt: {
      type: String,
      required: true
    },
  },
}
</script>

<style scoped>
a{
  color: #efecec;
  text-decoration: none; /* 移除链接下划线 */
  font-size: 0.875rem;
}
.image-link {
  display: inline-block;
  text-decoration: none; /* 移除链接下划线 */
}

.image-container {
  position: relative;
  display: inline-block; /* 确保图片容器的宽度与图片一致 */
  cursor: pointer; /* 鼠标指针变成手形 */
  width: 10.4375rem;
  height: 3.5rem;
}

.image {
  display: block; /* 避免图片下方出现间隙 */
  width: 100%; /* 让图片适应容器宽度 */
  height: 100%; /* 保持图片比例 */
}

.text-overlay {
  position: absolute;
  width: 3.5rem;
  //height: 40%;
  top: 50%;
  left: 40%;
  transform: translate(-35%, -50%); /* 使用transform实现精确居中 */
  color: white; /* 设置文字颜色 */
  font-size: 1vw; /* 使用响应式单位 */
  //background-color: rgba(0, 0, 0, 0); /* 设置半透明背景，提升文字可读性 */
  padding: 1vw; /* 设置响应式内边距 */
  border-radius: 0.5vw; /* 设置响应式圆角 */
  text-align: center; /* 居中文字 */
}
@media screen and (max-width: 1080px) {
  .text-overlay {
    position: absolute;
    width: 60%;
    //height: 40%;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%); /* 使用transform实现精确居中 */
    color: white; /* 设置文字颜色 */
    font-size: 4vw; /* 使用响应式单位 */
    //background-color: rgba(0, 0, 0, 0); /* 设置半透明背景，提升文字可读性 */
    padding: 1vw; /* 设置响应式内边距 */
    border-radius: 0.5vw; /* 设置响应式圆角 */
    text-align: center; /* 居中文字 */
  }
}
</style>
