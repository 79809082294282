// src/i18n.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locales/en.json'
import zh from './locales/zh.json'
import zh_tw from './locales/zh_tw.json'

Vue.use(VueI18n)

const messages = {
    en,
    zh,
    zh_tw
}

const i18n = new VueI18n({
    locale: 'zh', // 设置默认语言
    fallbackLocale: 'zh', // 设置回退语言
    messages
})

export default i18n
