<template>
  <div class="problem1-container">
    <div class="problem1-photo-div">
      <img src="@/assets/problem1.png" alt="" class="problem1_photo">
    </div>
    <br>
    <div class="problem1_title">{{$t('message.Glyphes-Strokes')}}</div>
    <br>
    <div class="problem1_txt" v-if=" this.$i18n.locale==='zh' ">
      许多检字法依赖于笔画和字形来分
      <br>
      类，但有时一个汉字的书写笔画和
      <br>
      其实际字形结构不完全一致，导致
      <br>
      检索时可能会出现混淆或错误。
      <br>
      例如，有些笔画数法可能会因不同字
      <br>
      体或书写方式而产生差异，影响准
      <br>
      确性。
      <br><br><br><br><br>
    </div>
    <div class="problem1_txt" v-if=" this.$i18n.locale==='zh_tw' ">
      許多檢字法依賴於筆劃和字形來分
      <br>
      類，但有時一個漢字的書寫筆劃和
      <br>
      其實際字形結構不完全一致，導致
      <br>
      檢索時可能會出現混淆或錯誤。
      <br>
      例如，有些筆劃數法可能會因不同字
      <br>
      體或書寫管道而產生差异，影響准
      <br>
      確性。
      <br><br><br><br><br>
    </div>
    <div class="problem1_txt_en" v-if=" this.$i18n.locale==='en' ">
      Many glyphics rely on strokes and
      <br>
      glyphics for classification, but
      <br>
      sometimes the written strokes of a
      <br>
      Chinese character are not completely
      <br>
      consistent with its actual glyphics,
      <br>
      which may lead to confusion or errors
      <br>
      in retrieval. For example, some stroke
      <br>
      count methods may vary according to
      <br>
      different fonts or writing methods,
      <br>
      affecting accuracy.
      <br> <br> <br><br> <br>
    </div>
  </div>
</template>
<script>
import '@/style/problemOne.css';
export default {
  name: 'ProblemOne',
}
</script>
<style scoped>
</style>