<template>
  <div class="chinese-input-method">
    <div class="chinese-input-method-font" v-if="$store.state.isChinese" style="font-size: 60px">
      <span :class="this.$i18n.locale==='en'?'chinese-input-method-font-span-en':'chinese-input-method-font-span'">{{$t('message.Full-Platform-Chinese')}}</span>
      <span :class="this.$i18n.locale==='en'?'chinese-input-method-font-span-en':'chinese-input-method-font-span'">{{$t('message.Visual-Keyboard')}}</span>
    </div>
    <div :class="this.$i18n.locale==='en'?'chinese-input-method-detail-en':'chinese-input-method-detail' " v-if="$store.state.isChinese">
      <br>
      {{$t('message.Chinese-Visual-Keyboard-detail')}}
      <br><br>
    </div>
    <div class="chinese-input-method-detail-en" v-else>
      <br>
      Currently commonly used input methods pinyin, stroke,
      voice, OCR four. In order to meet the needs of foreign
      Chinese learners, Chinese Key visual input method provides
      a solution, Chinese zero-based users can effectively locate
      and search Chinese characters through three steps according
      to graphic cognition
      <br><br>
    </div>
    <div class="image-container">
      <img src="../../assets/Rad_205.png"   alt="Example Image" class="image">
      <div class="text-overlay" @click="playVideo" v-if="$store.state.isChinese">{{$t('message.Watch-Video')}}</div>
    </div>
  </div>
</template>
<script>
// import ImageWithTxt from "@/components/FirstComponent/ImageWithTxt.vue";
import '@/style/chineseComponent.css';

export default {
  name: 'ChineseComponent',
  components: {
    // ImageWithTxt
  },
  methods: {
    playVideo() {
      this.$emit('play-video');
    }
  }
}
</script>
<style scoped>

</style>