<template>
  <div class="problem2-container">
    <div class="problem2-photo-div">
      <img src="@/assets/problem2.png" alt="" class="problem2_photo">
    </div>
    <br>
    <div class="problem2_title">{{$t('message.Difficulty-in-Classification')}}</div>
    <br>
    <div class="problem2_txt" v-if="this.$i18n.locale==='zh' ">
      对于结构复杂的汉字，现行的检字
      <br>
      法（如四角号码检字法或部首检字
      <br>
      法）可能会有困难。复杂字的笔画
      <br>
      多、部件多，导致查找过程繁琐、
      <br>
      效率低下，对于不熟悉这些字的人
      <br>
      来说，检索过程会更加困难。
      <br><br><br><br><br>
    </div>
    <div class="problem2_txt" v-if="this.$i18n.locale==='zh_tw'">
      對於結構複雜的漢字，現行的檢字
      <br>
      法（如四角號碼檢字法或部首檢字
      <br>
      法）可能會有困難。 複雜字的筆劃
      <br>
      多、部件多，導致查找過程繁瑣、
      <br>
      效率低下，對於不熟悉這些字的人
      <br>
      來說，檢索過程會更加困難。
      <br><br><br><br><br>
    </div>
    <div class="problem2_txt_en" v-if="this.$i18n.locale==='en' ">
      For Chinese characters with complex
      <br>
      structures, current glyphs such as
      <br>
      quadrature or radical glyphs may have
      <br>
      difficulties. Complex words have many
      <br>
      strokes and many parts, resulting in a
      <br>
      cumbersome and inefficient search
      <br>
      process, which can be more difficult for
      <br>
      people who are not familiar with these
      <br>
      words.
      <br><br><br><br><br>
    </div>

  </div>
</template>
<script>
import '@/style/problemTwo.css';

export default {
  name: 'ProblemOne',
}
</script>
<style scoped>
</style>