<template>
  <div class="problem3-container" >
    <div class="problem2-photo-div">
      <img src="@/assets/problem3.png" alt="" class="problem3_photo">
    </div>
    <br>
    <div class="problem3_title" v-if="$store.state.isChinese">{{$t('message.Consistency-Issues')}}</div>
    <br>
    <div class="problem3_txt" v-if="  this.$i18n.locale==='zh' ">
      由于汉字在不同的平台和系统上可
      <br>
      能有不同的编码方式 (如GB2312、
      <br>
      BIG5、Unicode等)，这导致了在不
      <br>
      同平台间的一致性问题。此外，汉
      <br>
      字在不同的语言和地区也可能有不
      <br>
      同的书写和使用习惯，这使得检字
      <br>
      法的普适性和一致性受到挑战。
    </div>
    <div class="problem3_txt" v-if="this.$i18n.locale==='zh_tw'">
      由於漢字在不同的平臺和系統上可
      <br>
      能有不同的編碼方式（如GB2312、
      <br>
      BIG5、Unicode等），這導致了在不
      <br>
      同平臺間的一致性問題。 此外，漢
      <br>
      字在不同的語言和地區也可能有不
      <br>
      同的書寫和使用習慣，這使得檢字
      <br>
      法的普適性和一致性受到挑戰。
    </div>
    <div class="problem3_txt_en" v-if="this.$i18n.locale==='en'">
      As Chinese characters may be encoded in
      <br>
      different ways on different platforms and
      <br>
      systems (such as GB2312, BIG5, Unicode,
      <br>
      etc.), this leads to consistency problems
      <br>
      between different platforms. In addition,
      <br>
      Chinese characters may also have different
      <br>
      writing and usage habits in different
      <br>
      languages and regions, which makes the
      <br>
      universality and consistency of the
      <br>
      orthography challenged.
    </div>
  </div>
</template>
<script>
// import ProblemThreePhoto from "@/components/SecondComponent/ProblemThreePhoto.vue";
import '@/style/problemThree.css';

export default {
  name: 'ProblemThree',
  components: {
    // ProblemThreePhoto
  }
}
</script>
<style scoped>
</style>