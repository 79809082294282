<template>
  <div id="app">
    <div class="section" id="0"><zero-component></zero-component></div>
  <router-view></router-view>
    <div class="section" id="9"><ninth-component></ninth-component></div>
  </div>
</template>

<script>
import ZeroComponent from "@/components/ZeroComponent.vue";
import NinthComponent from './components/NinthComponent.vue'

export default {
  name: 'App',
  components: {
    ZeroComponent,
    NinthComponent,
  },
  methods: {

  }
}
</script>
