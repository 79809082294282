<template>
  <div class="fiftyContainer">
    <br>
    <div class="partnerContainer"><fourth-component></fourth-component></div>

    <div class="oracleContainer"><key-group/></div>
    <div class="founderContainer"><sixth-component></sixth-component></div>
  </div>
</template>
<script>
import FourthComponent from '@/components/FiftyComponent/PartnersComponent.vue'

import SixthComponent from '@/components/SixthComponent/SixthComponent.vue'
import KeyGroup from "@/components/FiftyComponent/KeyGroup.vue";
export default{
  data() {
    return {
    }
  },
  components: {
    KeyGroup,
    FourthComponent,
    SixthComponent
  }
}
</script>

<style scoped>
.founderContainer{
  margin-top: 217px;
}
.partnerContainer{
  display: flex;
  justify-content: center;
}
.oracleContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Helvetica;
  margin-top: 215px;
}
.fiftyContainer{
  background-image: url('@/assets/globalimg.png');
  width: 100%;
  border-top: 0.2rem solid #f6f6f6;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-items: center;
  /*background-size: 80rem 80rem;*/
  background-size: 1130px 1130px ;
  background-repeat: no-repeat;
  background-position: center 15rem;
}
#The_best_framework_f{
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 2vw;
  width: 100vw;
  transform:translate(41% ,3416px);
}
@media screen and (min-width: 500px)  and (max-width: 1080px) {
  svg{
      height: 30vh;
  }
  #Sed_quis_tincidunt_s{
    font-size: 5.5vw;
  }
  #The_best_framework_f{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 20vw;
    width: 100vw;
    transform:translate(100% ,3416px);
  }
  #组_6945{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (min-width: 390px)  and (max-width: 500px){
  svg{
    height: 20rem;
  }
#The_best_framework_f{
    display: flex;
    flex-direction: column;
    transform:translate(30rem ,3490px);
    font-size: 20vw;
  }
  #Sed_quis_tincidunt_s{
    font-size: 6.8vw;
    transform:translate(0px ,3531px);
  }
}
</style>