<template>
  <div class="download-div">
    <br><br><br><br><br><br>
    <br><br><br><br><br><br>
    <br><br><br><br><br><br>
    <br><br><br><br><br><br>
    <br><br><br><br><br><br>
    <br><br><br><br><br><br>
    <br><br><br><br><br><br>
    <br><br><br><br><br><br>
    <br>
    <div class="download-div-left"><img class="download-pc-img" src="../assets/pc.png" alt=""></div>
    <div class="download-div-right">
      <div class="pc-title">{{$t('message.PC-Solution')}}</div>
      <br>
      <div class="pc-div">
        <span  class="pc-title-b"><b>{{$t('message.independent-application')}}</b></span>
        <br>
        <span  class="detail-des">{{$t('message.pc-desc-1')}}</span>
      </div>
      <div class="pc-div">
        <span  class="pc-title-b"><b>{{$t('message.client-sdk')}}</b></span>
        <br>
        <span class="detail-des">{{$t('message.pc-desc-2')}}</span>
      </div>
      <div class="pc-div">
        <span class="pc-title-b"><b>{{$t('message.api-sdk')}}</b></span>
        <br>
        <span class="detail-des">{{$t('message.pc-desc-3')}}</span>
      </div>
     <br><br>
      <div class="pc-div">
        <image-btn :txt="this.$i18n.locale==='en'?txt_en:txt" :link="link"></image-btn>
      </div>
    </div>
  </div>
</template>
<script>

import {defineComponent} from "vue";
import ImageBtn from "@/components/UtilsComponent/ImageBtn.vue";

export default defineComponent({
  components: {ImageBtn},
  data() {
    return {
      txt_en:"Waiting",
      txt: "暂无支持",
      link: ""
    }
  }
})
</script>
<style scoped>
.pc-title-b{
  line-height:2.5rem;
}
.detail-des{
  line-height: 1.625rem;
  color: #2F3447;
}
.download-div{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-family: Helvetica;
}
.pc-title{
  font-size: 2.75rem;
}
span{
  line-height: 1.625rem;
}
.pc-div{
  font-size: 1rem;
}
.download-div-left{
  width: 50%;
  display: flex;
  justify-content: center;
}
.download-div-right{
  width: 34.0625rem;
  height: 33.3125rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.download-pc-img{
  width: 27.4375rem;
  height: 33.3125rem;
}

</style>