<template>
  <div class="background-container">
<!--    <chinese-component></chinese-component>-->
    <div class="video-container">
      <!-- 嵌入视频 preload="auto"设置视频不进行预加载-->
      <video ref="myVideo"  preload="none" controls width="100%" height="100%">
        <source type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VideoComponent',
  components: {
  },
  computed: {
    isChinese() {
      return this.$i18n.locale==='zh';
    }
  },
  watch: {
    isChinese(newVal) {
      const videoElement = this.$refs.myVideo;
      videoElement.src = newVal
          ? "http://resource.wenzima.com/image/ime/vision001.mp4"
          : "http://resource.wenzima.com/image/ime/vision002.mp4";
      videoElement.load(); // 重新加载视频
      // videoElement.play(); // 播放视频, 语言改变也不自动播放视频
    }
  },
  mounted() {
    const videoElement = this.$refs.myVideo;
    videoElement.src = this.isChinese
        ? "http://resource.wenzima.com/image/ime/vision001.mp4"
        : "http://resource.wenzima.com/image/ime/vision002.mp4";
  },
  methods: {
    playVideo() {
      this.$refs.myVideo.play();
    },
  }
}
</script>

<style scoped>
.background-container {
  /*background-image: url('@/assets/Mockup_Group.png');*/
  //border-radius: 3vh; /* 设置圆角半径 */
  border-top-left-radius: 0.1vw; /* 左上角圆角 */
  border-bottom-left-radius: 0.1vw; /* 左下角圆角 */
  background-size: cover; /* 背景图像覆盖整个容器 */
  background-position: center; /* 背景图像居中 */
  //width: 55%;
  width: 80%;
  height: 22rem; /* 让背景容器高度占满视口 *//*控制video的外高度*/
  position: relative; /* 设置相对定位 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.video-container {
  max-width: 35.3125rem;
  //max-height: 100%;
  height: 21rem;  /*控制video的内高度，被外高度限制*/
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  /* 样式化视频容器 */
  border-radius: 0.5rem;
  border: 0.8125rem solid #cccccc; /* 灰色边框 */
  background: #000;  /*背景是黑色的，外框是灰色#cccccc*/
}
@media screen and (max-width: 1080px) {
  .background-container {
    /*background-image: url('@/assets/Mockup_Group.png');*/
    //border-radius: 3vh; /* 设置圆角半径 */
    border-top-left-radius: 5vw; /* 左上角圆角 */
    border-bottom-left-radius: 5vw; /* 左下角圆角 */
    background-size: cover; /* 背景图像覆盖整个容器 */
    background-position: center; /* 背景图像居中 */
    //width: 55%;
    width: 100%;
    height: 25vh; /* 让背景容器高度占满视口 */
    position: relative; /* 设置相对定位 */
    display: flex;
    justify-content: space-around; /* 水平居中 */
    align-items: center; /* 垂直居中 */
  }
}
@media screen and (max-width: 1280px) and (min-width:1080px) {
  .video-container {
    max-width: 35.3125rem;
    //max-height: 100%;
    height: 20rem;  /*控制video的内高度，被外高度限制*/
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    /* 样式化视频容器 */
    border-radius: 0.5rem;
    border: 0.8125rem solid #cccccc; /* 灰色边框 */
    background: #000;  /*背景是黑色的，外框是灰色#cccccc*/
  }
  .background-container {
    /*background-image: url('@/assets/Mockup_Group.png');*/
    //border-radius: 3vh; /* 设置圆角半径 */
    border-top-left-radius: 0.1vw; /* 左上角圆角 */
    border-bottom-left-radius: 0.1vw; /* 左下角圆角 */
    background-size: cover; /* 背景图像覆盖整个容器 */
    background-position: center; /* 背景图像居中 */
    //width: 55%;
    width: 94%;
    height: 22rem; /* 让背景容器高度占满视口 *//*控制video的外高度*/
    position: relative; /* 设置相对定位 */
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
  }
}
</style>
