<template>
  <div class="photoContainer">
    <div v-if="this.$i18n.locale==='zh'">
      <div class="download-photo-div"><img src="../../assets/watchWear.png" alt="" class="download-photo" style="width: 264px;height: 320px"></div>
      <div class="title-txt1"><h4>智能穿戴</h4></div>
      <div class="resolution-info">
        <span>&nbsp;利用汉字构件和笔画,配合便捷的按钮操作,实现高效输入的中文输入。</span>
      </div>
      <br>
      <div class="download-href">
        <a href="javascript:void(0)" @click="gotoDownload"> 了解更多</a>
      </div>
      &nbsp;
    </div>
    <!--    -->
    <div v-if="this.$i18n.locale==='zh_tw'">
      <div class="download-photo-div">
        <img src="../../assets/watchWear.png" alt="" class="download-photo" style="width: 264px;height: 320px">
      </div>
      <div class="title-txt1"><h4>智慧穿戴</h4></div>
      <div class="resolution-info">
        <span>&nbsp;利用漢字構件和筆劃,配合便捷的按鈕操作,實現高效輸入的中文輸入。</span>
      </div>
      <br>
      <div class="download-href">
        <a href="javascript:void(0)" @click="gotoDownload"> 瞭解更多</a>
      </div>
      &nbsp;
    </div>
<!--    -->
    <div v-if="this.$i18n.locale==='en'">
      <div class="download-photo-div"><img src="../../assets/watchWear.png" alt="" class="download-photo" style="width: 264px;height: 320px"></div>
      <div class="title-txt1"><h3>Smart Wear</h3></div>
      <div class="resolution-info">
        <span style="height: 7rem">Using Chinese character components and strokes,with convenient button components and strokes to simplify operation,achieve efficient input of Chinese input</span>
      </div>
      <br><br>
      <div class="download-href">
        <a href="javascript:void(0)" @click="gotoDownload"> more</a>
      </div>
      &nbsp;
    </div>
  </div>
</template>
<script>
export default {
  name:'WatchComponent',
  methods:{
    gotoDownload(){
      this.$router.push('/watch').then(()=>{
        window.scroll(0,0) //scroll to top
      })
    }
  }
}
</script>
<style scoped>
.download-photo-div{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.download-photo{
  //width: 70%;
  //height: 91%;
  width: 100%;
  height: 100%;
}
.photoContainer{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 18%;
  height: 18%;
}
.title-txt1{
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: Helvetica;
  font-size: 1.125rem;
}
.resolution-info{
  color: #9498ab;
  display: flex;
  /*height: 5rem;*/
  letter-spacing: 0.01vw;
  line-height:2.5vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Helvetica;
  font-size:0.875rem ;
  text-align: center;
}
.download-href{
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: Helvetica;
  font-size:0.875rem ;
  line-height:2.5rem
}
.download-href a{
  color: black;
}
@media screen and (max-width: 1080px) {
  .photoContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 1280px) and (min-width:1080px) {
  .resolution-info{
    color: #9498ab;
    display: flex;
    /*height: 5rem;*/
    letter-spacing: 0.02vw;
    line-height:20px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size:0.875rem ;
    font-family: Helvetica;
  }
}
</style>
