<template>
<div class="photoContainer">
  <div class="pc-photo-container" v-if=" this.$i18n.locale==='zh' ">
    <div class="download-photo-div"><img src="../../assets/pc.png" alt="" class="download-photo" style="width: 264px;height: 320px"></div>
    <div class="title-txt1"><h4>电脑端</h4></div>
    <div class="resolution-info">
      <span>通过重构汉字构件和笔画,提供高效精准输入的创新键盘中文输入法。</span>
    </div>
    <br>
    <div class="download-href"><a href="javascript:void(0)" @click="gotoDownload">了解更多</a></div>
    &nbsp;
  </div>
<!--  -->
  <div class="pc-photo-container" v-if=" this.$i18n.locale==='zh_tw' ">
    <div class="download-photo-div"><img src="../../assets/pc.png" alt="" class="download-photo" style="width: 264px;height: 320px"></div>
    <div class="title-txt1"><h4>電腦端</h4></div>
    <div class="resolution-info">
      <span>通過重構漢字構件和筆劃,提供高效精准輸入的創新鍵盤中文輸入法。</span>
    </div>
    <br>
    <div class="download-href"><a href="javascript:void(0)" @click="gotoDownload">瞭解更多</a></div>
    &nbsp;
  </div>
<!---->
  <div class="pc-photo-container" v-if=" this.$i18n.locale==='en' ">
    <div class="download-photo-div"><img src="../../assets/pc.png" alt="" class="download-photo" style="width: 264px;height: 320px"></div>
    <div class="title-txt1"><h3>PC</h3></div>
    <div class="resolution-info">
      <span style="height: 7rem">By reconstructing the components and strokes of Chinese characters,it provides an innovative keyboard Chinese input method with efficient and accurate input.</span>
    </div>
    <br><br>
    <div class="download-href"><a href="javascript:void(0)" @click="gotoDownload">more</a></div>
    &nbsp;
  </div>
</div>
</template>
<script>
export default {
  name:'PcComponent',
  methods:{
    gotoDownload(){
      this.$router.push('/pc').then(()=>{
        window.scroll(0,0) //scroll to top
      })
    }
  }
}
</script>
<style scoped>
.download-a{
  color: #130101;
  /* pointer-events: none; 禁用指针事件 */
  /*  cursor: not-allowed; 显示禁用光标 */
  /*text-decoration: none;  去掉下划线 */
  font-family: Helvetica;
}
.download-photo-div{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.download-photo{
  //width: 70%;
  //height: 91%;
  width: 100%;
  height: 100%;
}
.photoContainer{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 18%;
  height: 18%;
}
.title-txt1{
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: Helvetica;
  font-size: 1.125rem;
}
.resolution-info{
  color: #9498ab;
  display: flex;
  /*height: 5rem;*/
  letter-spacing: 0.02vw;
  line-height:2.5vh;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size:0.875rem ;
  font-family: Helvetica;
}
.download-href{
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  font-size:0.875rem ;
  line-height:2.5rem
}
.download-href a{
  color: #0e0000;
  font-family: Helvetica;
}
@media screen and (max-width: 1080px) {
  .photoContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 1280px) and (min-width:1080px) {
  .resolution-info{
    color: #9498ab;
    display: flex;
    /*height: 5rem;*/
    letter-spacing: 0.02vw;
    line-height:20px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size:0.875rem ;
    font-family: Helvetica;
  }
}
</style>
