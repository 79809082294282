<template>
  <div class="faq-container" id="section7">
    <div class="faq-header">{{$t('message.common-problem')}}</div>
    <div  class="qa" v-if="this.$i18n.locale==='zh' ">
      <div class="faq-item" v-for="(faq, index) in faqs_cn" :key="index">
        <div :class="faq.isBorderRadius?title:title1" @click="toggle_cn(index)">
          {{ faq.question }}
          <span class="arrow" :class="{ open: faq.open }"></span>
        </div>
        <div class="faq-content" v-if="faq.open">
          {{ faq.answer }}
          <br>
          <img v-if="index===3" src="../assets/chu_build.png"  alt="" style="width: 100%;height: 100%"/>
        </div>
      </div>
      <br><br><br><br>
    </div>
<!--    -->
    <div class="qa" v-if="this.$i18n.locale==='zh_tw' ">
      <div class="faq-item" v-for="(faq, index) in faqs_tw" :key="index">
        <div :class="faq.isBorderRadius?title:title1" @click="toggle_tw(index)">
          {{ faq.question }}
          <span class="arrow" :class="{ open: faq.open }"></span>
        </div>
        <div class="faq-content" v-if="faq.open">
          {{ faq.answer }}
          <br>
          <img v-if="index===3" src="../assets/chu_build.png"  alt="" style="width: 100%;height: 100%"/>
        </div>
      </div>
      <br><br><br><br>
    </div>
<!--    -->
    <div class="qa" v-if="this.$i18n.locale==='en' ">
      <div class="faq-item" v-for="(faq, index) in faqs_en" :key="index">
        <div :class="faq.isBorderRadius?title:title1" @click="toggle_en(index)">
          {{ faq.question }}
          <span class="arrow" :class="{ open: faq.open }"></span>
        </div>
        <div class="faq-content" v-if="faq.open">
          {{ faq.answer }}
          <br>
          <img v-if="index===3" src="@/assets/chu_build.png"  alt="" style="width: 100%;height: 100%"/>
        </div>
      </div>
      <br><br><br><br>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/eventBus/eventBus";

export default {
  data() {
    return {
      title:'title', //未展开状态，四个角都有圆角
      title1:'title1',// 展开状态，上方两个角是圆角，下边两个角是直角
      faqs_cn: [
        {
          question: '什么是视觉检字法和输入法?',
          answer: '中文之钥视觉检字法和输入法(“视觉输入法”)是一款专为中文非母语人士和中文研究者设计的，以8万+字、60万+词语、200万+句子为基础数据支撑，有别于传统拼音、部首笔画的一种全新的、独创便捷的汉字检索和输入方式，即便使用者对中文一无所知，也可根据汉字的图形认知通过三步定位检索或输入汉字。此方法检索或输入一个汉字的时间通常不超过 15 秒，让使用者实现简易、快速检索和输入中文的目的。',
          open: false,
          isBorderRadius: true,
        },
        {
          question: '谁会使用视觉检字法和输入法?',
          answer: '中文输入最大的痛点是拼音和汉字的记忆和输入困难,视觉输入法从根本上解决了这两个痛点，使用者完全不需要背记汉字和拼音，即可无障碍实现汉字输入。对于中文输入法工具来说，输入效率最重要。视觉输入法可以促进单位和企业获取更多非母语、初学、相关研究机构等中文使用者用户。',
          open: false,
          isBorderRadius: true,
        },
        {
          question: '为什么使用视觉检字法和输入法?',
          answer: '视觉输入法已获得 14项国际发明专利，覆盖31个国家专利授权。第一，不要求使用者有任何中文基础，上手快，更适合不懂中文的用户;第二，支持 8W+汉字，生僻字无乱码显示;第三，输入键盘简易8键，易操作，提升输入效率，可填补穿戴设备输入法的空白，适配各生态的终端设备。',
          open: false,
          isBorderRadius: true,
        },
        {
          question: '如何使用视觉检字法和输入法?',
          answer: '示例:三步定位“楚”字',
          open: false,
          isBorderRadius: true,
        },
      ],
      faqs_en: [
        {
          question: 'What is Chinese Key Visual Retrieve and Input Method? ',
          answer: 'Chinese Key Visual Retrieve and Input Method ("Visual Input Method") is a new, original and convenient way of searching and inputting Chinese characters, different from the traditional Pinyin and radical strokes, designed for non-native Chinese speakers and Chinese researchers, based on the data support of 80,000+ characters, 600,000+ words and 300,000+ sentences. Even if the user has no knowledge of Chinese, he/she can retrieve or input a Chinese character in three steps according to the graphical recognition of the character.\n' +
              'This method usually takes less than 15 seconds to retrieve or input a Chinese character, allowing users to realize easy and fast retrieval and input of Chinese characters.',
          open: false,
          isBorderRadius: true,
        },
        {
          question: 'Who will use Chinese Key Visual Retrieve and Input Method?',
          answer: 'The biggest pain point of Chinese input is the difficulty of memorising and inputting Pinyin and Chinese characters, Visual Input Method solves these two pain points fundamentally, users do not need to memorise Chinese characters and Pinyin at all, and can input Chinese characters without any obstacles.\n' +
              'For Chinese input method tools, input efficiency is the most important. With Visual Input Method, more Chinese users such as non-native speakers, beginners, and related research institutes can be acquired.',
          open: false,
          isBorderRadius: true,
        },
        {
          question: 'Why you should choose Chinese Key Visual Retrieve and Input Method ? ',
          answer: 'Visual Input Method has been granted 14 international patents covering 31 countries.\n' +
              'Firstly, it doesn\'t require users to have any basic knowledge of Chinese, quick to learn, suitable for any users even those who don’t know Chinese. Secondly, it supports 8W+ Chinese characters, no garbled display for rare characters. Thirdly, it use simple 8-key input keyboard, easy to operate, improve input efficiency, fill the gap of input method for wearable devices, and adapt to various ecological terminal devices. ',
          open: false,
          isBorderRadius: true,
        },
        {
          question: 'How to use Chinese Key Visual Retrieve and Input Method?',
          answer: 'Example: Three steps to locate the Chinese character "楚",',
          open: false,
          isBorderRadius: true,
        }
      ],
      faqs_tw:[
        {
          question: '什麼是視覺檢字法和輸入法？',
          answer: '中文之鑰視覺檢字法和輸入法（“視覺輸入法”）是一款專為中文非母語人士和中文研究者設計的，以8萬+字、60萬+詞語、200萬+句子為基礎數據支撐，有別於傳統拼音、部首筆劃的一種全新的、獨創便捷的漢字檢索和輸入管道，即便使用者對中文一無所知，也可根據漢字的圖形認知通過三步定位檢索或輸入漢字。 此方法檢索或輸入一個漢字的時間通常不超過15秒，讓使用者實現簡易、快速檢索和輸入中文的目的。',
          open: false,
          isBorderRadius: true,
        },
        {
          question: '誰會使用視覺檢字法和輸入法？',
          answer: '中文輸入最大的痛點是拼音和漢字的記憶和輸入困難，視覺輸入法從根本上解决了這兩個痛點，使用者完全不需要背記漢字和拼音，即可無障礙實現漢字輸入。 對於中文輸入法工具來說，輸入效率最重要。 視覺輸入法可以促進組織和企業獲取更多非母語、初學、相關研究機構等中文使用者用戶。',
          open: false,
          isBorderRadius: true,

        },
        {
          question: '為什麼使用視覺檢字法和輸入法？ ',
          answer: '視覺輸入法已獲得14項國際發明專利，覆蓋31個國家專利授權。 第一，不要求使用者有任何中文基礎，上手快，更適合不懂中文的用戶； 第二，支持8W+漢字，生僻字無亂碼顯示； 第三，輸入鍵盤簡易8鍵，易操作，提升輸入效率，可填補穿戴設備輸入法的空白，適配各生態的終端設備。',
          open: false,
          isBorderRadius: true,

        },
        {
          question: '如何使用視覺檢字法和輸入法？',
          answer: '示例：三步定位“楚”字',
          open: false,
          isBorderRadius: true,
        }
      ],
    };
  },
  methods: {
    handleScrollToSection(sectionId) {
      const section = document.getElementById(sectionId)
      if (section) {
        section.scrollIntoView({behavior: 'smooth'});
      }
    },
    toggle_cn(index) {
      this.faqs_cn[index].open = !this.faqs_cn[index].open;
      this.faqs_cn[index].isBorderRadius=!this.faqs_cn[index].isBorderRadius;
    },
    toggle_en(index) {
      this.faqs_en[index].open = !this.faqs_en[index].open;
      this.faqs_en[index].isBorderRadius=!this.faqs_en[index].isBorderRadius;
    },
    toggle_tw(index) {
      this.faqs_tw[index].open = !this.faqs_tw[index].open;
      this.faqs_tw[index].isBorderRadius=!this.faqs_tw[index].isBorderRadius;
    },
  },
  mounted() {
    EventBus.$on('scrollToSection', this.handleScrollToSection);
  },
  beforeDestroy() {
    EventBus.$off('scrollToSection', this.handleScrollToSection);
  },
};
</script>

<style>
html {
  font-size: 16px; /* Base font size */
}

.faq-container {
  max-width: 63%; /* 600px / 16 = 37.5rem */
  margin: 0 auto;
  padding: 0; /* 20px / 16 = 1.25rem */
}
.qa{
margin-top: 49px;
}
.faq-header {
  text-align: center;
  margin-top: 49px;
  //font-size: 1.5rem; /* 24px / 16 = 1.5rem */
  font-size: 2.125rem; /* 24px / 16 = 1.5rem */
  margin-bottom: 1.25rem; /* 20px / 16 = 1.25rem */
  font-family: Helvetica;
}

.faq-item {
  //border: 0.0625rem solid #ccc; /* 1px / 16 = 0.0625rem */
  width: 58.125rem;
  border-radius: 0.3125rem; /* 5px / 16 = 0.3125rem */
  margin-bottom: 0.625rem; /* 10px / 16 = 0.625rem */
}

.title {
  background-color: #EDEDED;
  border-radius: 0.625rem;
  line-height: 1.875rem;
  padding: 0.9375rem; /* 15px / 16 = 0.9375rem */
  cursor: pointer;
  font-size: 1rem; /* 18px / 16 = 1.125rem */
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Helvetica;
}
.title1{
  background-color: #EDEDED;
  line-height: 1.875rem;
  border-radius: 0.625rem 0.625rem 0 0;
  padding: 0.9375rem; /* 15px / 16 = 0.9375rem */
  cursor: pointer;
  font-size: 1rem; /* 18px / 16 = 1.125rem */
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Helvetica;
}
.faq-content {
  background-color: #EDEDED;
  padding: 0.9375rem; /* 15px / 16 = 0.9375rem */
  font-size: 0.875rem;
  border-radius: 0 0 0.625rem 0.625rem;
  font-family: Helvetica;
  line-height: 1.375rem;
}

.arrow {
  display: inline-block;
  width: 0.625rem; /* 10px / 16 = 0.625rem */
  height: 0.625rem; /* 10px / 16 = 0.625rem */
  border-right: 0.125rem solid #000; /* 2px / 16 = 0.125rem */
  border-bottom: 0.125rem solid #000; /* 2px / 16 = 0.125rem */
  transform: rotate(-45deg);
  transition: transform 0.3s;
  font-family: Helvetica;
}

.arrow.open {
  transform: rotate(43deg); /* Rotate to point down */
}

@media (max-width: 48rem) {
  /* 768px / 16 = 48rem */
  .faq-container {
    width: 100%;
    padding: 0.625rem; /* 10px / 16 = 0.625rem */
  }

  .faq-title {
    font-size: 1rem; /* 16px / 16 = 1rem */
    padding: 0.625rem; /* 10px / 16 = 0.625rem */
  }

  .faq-content {
    padding: 0.625rem; /* 10px / 16 = 0.625rem */
    font-size: 0.875rem; /* 14px / 16 = 0.875rem */
  }

  .faq-item {
    border: 0.0625rem solid #ccc; /* 1px / 16 = 0.0625rem */
    width: 65vw;
    border-radius: 0.3125rem; /* 5px / 16 = 0.3125rem */
    margin-bottom: 0.625rem; /* 10px / 16 = 0.625rem */
  }
}

@media (max-width: 1080px) {
  .faq-header {
    text-align: center;
    //font-size: 1.5rem; /* 24px / 16 = 1.5rem */
    font-size: large; /* 24px / 16 = 1.5rem */
    margin-bottom: 1.25rem; /* 20px / 16 = 1.25rem */
    font-family: Helvetica;
  }
}
@media screen and (max-width: 1280px) and (min-width:1080px) {
  .faq-container {
    max-width: 75%; /* 600px / 16 = 37.5rem */
    margin: 0 auto;
    padding: 0; /* 20px / 16 = 1.25rem */
  }
}
</style>
