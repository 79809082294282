import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from '@/router/router'
Vue.config.productionTip = false
import store from "@/store";
Vue.use(ElementUI);
Vue.use(router)
new Vue({
    render: h => h(App),
    store,
    i18n,
    router,
}).$mount('#app')
