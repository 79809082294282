<template>
  <div class="problem-container" id="section2">
    <div style="margin-top: 75px">
      <div class="problem-txt">{{$t('message.The-Problems')}}</div>
      <br>
      <br>
    </div>
    <div class="problem-download">
      <problem-one></problem-one>
      <problem-two></problem-two>
      <problem-three></problem-three>
    </div>
  </div>
</template>
<script>
import '@/style/secondComponent.css';
import ProblemOne from "@/components/SecondComponent/ProblemOne.vue";
import ProblemTwo from "@/components/SecondComponent/ProblemTwo.vue";
import ProblemThree from "@/components/SecondComponent/ProblemThree.vue";
import {EventBus} from "@/eventBus/eventBus";
export default {
  name: 'SecondComponent',
  components: {
    ProblemOne,
    ProblemTwo,
    ProblemThree,
  },
  methods:{
    handleScrollToSection(sectionId) {
      const section = document.getElementById(sectionId)
      if (section) {
        section.scrollIntoView({behavior: 'smooth'});
      }
    }
  },
  mounted() {
    EventBus.$on('scrollToSection', this.handleScrollToSection);
  },
  beforeDestroy() {
    EventBus.$off('scrollToSection', this.handleScrollToSection);
  },
}
</script>
<style scoped>

</style>