<template>
  <div class="social-component">
    <a :href=href>
      <img class="social-img" :src="src" alt="" style="width: 3rem;height: 3rem">
    </a>
  </div>
</template>
<script>
export default {
  name:"SocialComponent",
  props: {
    href: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>
.social-component{
  width:3.5rem
}
.social-img {
  width:100%;
  height:100%
}
</style>