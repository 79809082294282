var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"photoContainer"},[( this.$i18n.locale==='zh' )?_c('div',{staticClass:"pc-photo-container"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('br'),_c('div',{staticClass:"download-href"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.gotoDownload}},[_vm._v("了解更多")])]),_vm._v("   ")]):_vm._e(),( this.$i18n.locale==='zh_tw' )?_c('div',{staticClass:"pc-photo-container"},[_vm._m(3),_vm._m(4),_vm._m(5),_c('br'),_c('div',{staticClass:"download-href"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.gotoDownload}},[_vm._v("瞭解更多")])]),_vm._v("   ")]):_vm._e(),( this.$i18n.locale==='en' )?_c('div',{staticClass:"pc-photo-container"},[_vm._m(6),_vm._m(7),_vm._m(8),_c('br'),_c('br'),_c('div',{staticClass:"download-href"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.gotoDownload}},[_vm._v("more")])]),_vm._v("   ")]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-photo-div"},[_c('img',{staticClass:"download-photo",staticStyle:{"width":"264px","height":"320px"},attrs:{"src":require("../../assets/pc.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-txt1"},[_c('h4',[_vm._v("电脑端")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolution-info"},[_c('span',[_vm._v("通过重构汉字构件和笔画,提供高效精准输入的创新键盘中文输入法。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-photo-div"},[_c('img',{staticClass:"download-photo",staticStyle:{"width":"264px","height":"320px"},attrs:{"src":require("../../assets/pc.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-txt1"},[_c('h4',[_vm._v("電腦端")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolution-info"},[_c('span',[_vm._v("通過重構漢字構件和筆劃,提供高效精准輸入的創新鍵盤中文輸入法。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-photo-div"},[_c('img',{staticClass:"download-photo",staticStyle:{"width":"264px","height":"320px"},attrs:{"src":require("../../assets/pc.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-txt1"},[_c('h3',[_vm._v("PC")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolution-info"},[_c('span',{staticStyle:{"height":"7rem"}},[_vm._v("By reconstructing the components and strokes of Chinese characters,it provides an innovative keyboard Chinese input method with efficient and accurate input.")])])
}]

export { render, staticRenderFns }