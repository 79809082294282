var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"photoContainer"},[(this.$i18n.locale==='zh')?_c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_c('br'),_c('div',{staticClass:"download-href"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.gotoDownload}},[_vm._v(" 了解更多")])]),_vm._v("   ")]):_vm._e(),(this.$i18n.locale==='zh_tw')?_c('div',[_vm._m(3),_vm._m(4),_vm._m(5),_c('br'),_c('div',{staticClass:"download-href"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.gotoDownload}},[_vm._v(" 瞭解更多")])]),_vm._v("   ")]):_vm._e(),(this.$i18n.locale==='en')?_c('div',[_vm._m(6),_vm._m(7),_vm._m(8),_c('br'),_c('br'),_c('div',{staticClass:"download-href"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.gotoDownload}},[_vm._v(" more")])]),_vm._v("   ")]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-photo-div"},[_c('img',{staticClass:"download-photo",staticStyle:{"width":"264px","height":"320px"},attrs:{"src":require("../../assets/watchWear.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-txt1"},[_c('h4',[_vm._v("智能穿戴")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolution-info"},[_c('span',[_vm._v(" 利用汉字构件和笔画,配合便捷的按钮操作,实现高效输入的中文输入。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-photo-div"},[_c('img',{staticClass:"download-photo",staticStyle:{"width":"264px","height":"320px"},attrs:{"src":require("../../assets/watchWear.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-txt1"},[_c('h4',[_vm._v("智慧穿戴")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolution-info"},[_c('span',[_vm._v(" 利用漢字構件和筆劃,配合便捷的按鈕操作,實現高效輸入的中文輸入。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-photo-div"},[_c('img',{staticClass:"download-photo",staticStyle:{"width":"264px","height":"320px"},attrs:{"src":require("../../assets/watchWear.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-txt1"},[_c('h3',[_vm._v("Smart Wear")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolution-info"},[_c('span',{staticStyle:{"height":"7rem"}},[_vm._v("Using Chinese character components and strokes,with convenient button components and strokes to simplify operation,achieve efficient input of Chinese input")])])
}]

export { render, staticRenderFns }