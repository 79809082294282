<template>
  <div class="zero-nav">
    <el-menu :default-active="activeIndex" class="el-menu-demo" text-color="black" active-text-color="#000007"
             mode="horizontal" @select="scrollToSection">
      <el-menu-item index="0" class="logo-item"
                    style="font-family: Helvetica;border-bottom: none;cursor: default;color: #000007;font-size: 1rem;width: 16%;display:flex;justify-content: flex-start">
        <logo-component></logo-component>
        <span style="margin-left:1.375rem;font-size: 1rem;font-weight: bold;display: flex;justify-content: center;align-items: center">{{ $t('message.Chinese-Visual-Keyboard') }}</span>
      </el-menu-item>
      <div style="display: flex; width: 72%;justify-content: center">
        <el-menu-item index="1" class="top-nav" style="font-family: Helvetica;height: 100%;width: 7.5rem;display: flex;justify-content: center">
            {{ $store.state.isDownloaded === 'home' ? $t('message.HomePage') : $t('message.return  ') }}
        </el-menu-item>

        <el-menu-item index="3" class="top-nav" style="font-family: Helvetica;height: 100%;width: 7.5rem;display: flex;justify-content: center">
            {{ $store.state.isDownloaded === 'home' ? $t('message.service') : "" }}
        </el-menu-item>

        <el-menu-item index="6" class="top-nav" style="font-family: Helvetica;height: 100%;width: 7.5rem;display: flex;justify-content: center">
          {{ $store.state.isDownloaded === 'home' ? $t('message.aboutUs') : "" }}
        </el-menu-item>

        <el-menu-item index="7" class="top-nav" style="font-family: Helvetica;height: 100%;width: 7.5rem;display: flex;justify-content: center">
            {{ $store.state.isDownloaded === 'home' ? $t('message.question') : "" }}
        </el-menu-item>

      </div>


      <!--      <el-submenu index="2">-->
      <!--        <template slot="title"><span>{{ $t('message.service') }}</span></template>-->
      <!--        <el-menu-item index="2-1" style="font-family: Helvetica;">选项1</el-menu-item>-->
      <!--        <el-menu-item index="2-2" style="font-family: Helvetica;">选项2</el-menu-item>-->
      <!--        <el-menu-item index="2-3" style="font-family: Helvetica;">选项3</el-menu-item>-->
      <!--        <el-submenu index="2-4">-->
      <!--          <template slot="title">常见问题</template>-->
      <!--          <el-menu-item index="2-4-1" style="font-family: Helvetica;">选项1</el-menu-item>-->
      <!--          <el-menu-item index="2-4-2" style="font-family: Helvetica;">选项2</el-menu-item>-->
      <!--          <el-menu-item index="2-4-3" style="font-family: Helvetica;">选项3</el-menu-item>-->
      <!--        </el-submenu>-->
      <!--      </el-submenu>-->
      <el-menu-item index="#" style="
      font-family: Helvetica;
      border-bottom: none;
      cursor: default;
      color: #000007;
      height: 100%;
      width: 12%;
      justify-content: center;
      background:#ffffff;
      align-items: center;">
        <global-component></global-component>
      </el-menu-item>
    </el-menu>
    <!--    <div class="line"></div>-->
  </div>
</template>

<script>
import LogoComponent from "@/components/FirstComponent/LogoComponent.vue";
import GlobalComponent from "@/components/FirstComponent/GlobalComponent.vue";
import {EventBus} from "@/eventBus/eventBus";

export default {
  components: {GlobalComponent, LogoComponent},
  data() {
    return {
      activeIndex: '1',
      activeIndex2: '1'
    };
  },
  methods: {
    scrollToSection(sectionId) {
      if (sectionId==='#'){ //切换语言的时候，不要发生滑动
        return
      }
      var section = 'section'+sectionId
      console.log(section)
      if (this.$router.currentRoute.path !== '/') {
        this.$router.push('/').then(()=>{
          EventBus.$emit('scrollToSection', section)
        })
      }else {
        EventBus.$emit('scrollToSection', section)
      }
    }
  }
}
</script>

<style scoped>
.el-menu-demo {
  display: flex;
  height: 72px;
  width: 100%;
  align-items: center;
}

.el-menu.el-menu--horizontal {
  box-shadow: 0.1rem 0.1rem 1rem 0.1rem #dedede;
}

el-menu-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 0.875rem;
  font-family: Helvetica;
}

.logo-item:hover {
  color: #000000;
}

.el-menu-item.is-active {
  font-weight: bold;
  background-color: #ffffff;
  border-radius: 1rem;
}

.el-menu-item:hover {
  background-color: #ffffff; /* 鼠标悬浮时的背景色 */
}
li{
  background: #ffffff;
}
.logo-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.el-dropdown-menu__item, .el-menu-item { /*不能删除,删除会bug*/
  padding: 0;
  font-family: Helvetica;
}

.el-dropdown-menu__item {
  font-family: Helvetica;
}

span {
  font-family: Helvetica;
}

.zero-nav {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  /*//先固定72px*/
  height: 72px;
}

.line {
  height: 0.05rem;
  box-shadow: 0 0.1rem 0.1rem 0.1rem #dedede;
}

</style>
