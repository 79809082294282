<template>
  <div class="download-div">
    <br><br><br><br><br><br>
    <br><br><br><br><br><br>
    <br><br><br><br><br><br>
    <br><br><br><br><br><br>
    <br><br><br><br><br><br>
    <br><br><br><br><br><br>
    <br><br><br><br><br><br>
    <br><br><br><br><br><br>
    <br>
    <div class="download-div-left"><img class="download-pc-img" src="../assets/robot.png" alt=""></div>
    <div class="download-div-right">
      <div class="pc-title">
        {{$t('message.robot-solutions')}}
        <br> <br>
        <div class="pc-div">
          <span  class="pc-title-b"><b>{{$t('message.smart-device-sdk')}}</b></span>
          <br>
          <span class="smart-device">{{$t('message.smart-device-desc')}}</span>
        </div>
      </div>
      <br><br>
      <div class="pc-div">
        <image-btn :txt="txt" :link="link"></image-btn>
      </div>
    </div>
  </div>
</template>
<script>

import {defineComponent} from "vue";
import ImageBtn from "@/components/UtilsComponent/ImageBtn.vue";

export default defineComponent({
  components: {ImageBtn},
  data() {
    return {
      txt_en:"Waiting",
      txt: "暂无支持",
      link: ""
    }
  }
})
</script>
<style scoped>
.pc-title-b{
  line-height:2.5rem;
}
.detail-des{
  line-height: 1.625rem;
}
.smart-device{
  color: #2F3447;
}
.download-div{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-family: Helvetica;
}
.pc-title{
  font-size: 2.75rem;
}
span{
  line-height: 1.625rem;
}
.pc-div{
  font-size: 1rem;
}
.download-div-left{
  width: 50%;
  display: flex;
  justify-content: center;
}
.download-div-right{
  width: 34.0625rem;
  height: 33.3125rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.download-pc-img{
  width: 27.4375rem;
  height: 33.3125rem;
}
</style>