<template>
  <div class="SeventhComponent" :style="backgroundStyle">
    <div class="seventh-left" style="margin-top: 2rem">
      <div class="seventh-word-section">
        <span class="seventh-title-span" style="font-size: 2.125rem">{{ $t('message.Visual-Keyboard2') }}</span>
<br><br>
        <span class="seventh-detail-span" v-if="this.$i18n.locale==='zh' " style="font-family: Helvetica;line-height: 1.375rem;font-size: 18px">汉语零基础的用户可以根据图形认知通<br></span>
        <span class="seventh-detail-span" v-if="this.$i18n.locale==='zh'"
              style="font-family: Helvetica;font-size: 18px">过三步有效定位搜索汉字</span>
        <span class="seventh-detail-span" v-if="this.$i18n.locale==='zh_tw' " style="font-family: Helvetica;">漢語零基礎的用戶可以根據圖形認知通<br></span>
        <span class="seventh-detail-span" v-if="this.$i18n.locale==='zh_tw'"
              style="font-family: Helvetica;">過三步有效定位蒐索漢字</span>
        <span v-if="this.$i18n.locale ==='en' " class="seventh-detail-span" style="font-family: Helvetica;font-size: 18px">Chinese Key Visual Search and Input Method</span>
        <br>
        <span v-if="this.$i18n.locale ==='en'" class="seventh-detail-span" style="font-family: Helvetica;font-size: 18px">——Help users to lookup and input Chinese </span>
        <br>
        <span v-if="this.$i18n.locale ==='en'" class="seventh-detail-span" style="font-family: Helvetica;font-size: 18px">characters easily in three steps</span>
      </div>
      <br>

      <div style="display: flex;flex-direction: column;align-items: flex-start">
      <span class="seventh-ico" style="font-family: Helvetica;">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="1vw" height="2vh"><path
    d="M3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM20 7.23792L12.0718 14.338L4 7.21594V19H20V7.23792ZM4.51146 5L12.0619 11.662L19.501 5H4.51146Z"></path></svg>
        &nbsp;&nbsp;&nbsp;&nbsp;info@chinesekey.org
      </span>
        <br><br>
        <span class="seventh-ico" style="font-family: Helvetica;">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="1vw" height="2vh"><path
    d="M9.36556 10.6821C10.302 12.3288 11.6712 13.698 13.3179 14.6344L14.2024 13.3961C14.4965 12.9845 15.0516 12.8573 15.4956 13.0998C16.9024 13.8683 18.4571 14.3353 20.0789 14.4637C20.599 14.5049 21 14.9389 21 15.4606V19.9234C21 20.4361 20.6122 20.8657 20.1022 20.9181C19.5723 20.9726 19.0377 21 18.5 21C9.93959 21 3 14.0604 3 5.5C3 4.96227 3.02742 4.42771 3.08189 3.89776C3.1343 3.38775 3.56394 3 4.07665 3H8.53942C9.0611 3 9.49513 3.40104 9.5363 3.92109C9.66467 5.54288 10.1317 7.09764 10.9002 8.50444C11.1427 8.9484 11.0155 9.50354 10.6039 9.79757L9.36556 10.6821ZM6.84425 10.0252L8.7442 8.66809C8.20547 7.50514 7.83628 6.27183 7.64727 5H5.00907C5.00303 5.16632 5 5.333 5 5.5C5 12.9558 11.0442 19 18.5 19C18.667 19 18.8337 18.997 19 18.9909V16.3527C17.7282 16.1637 16.4949 15.7945 15.3319 15.2558L13.9748 17.1558C13.4258 16.9425 12.8956 16.6915 12.3874 16.4061L12.3293 16.373C10.3697 15.2587 8.74134 13.6303 7.627 11.6707L7.59394 11.6126C7.30849 11.1044 7.05754 10.5742 6.84425 10.0252Z"></path></svg>
        &nbsp;&nbsp;&nbsp;&nbsp;00065 80274125
      </span>
        <br><br>
        <span class="seventh-ico" style="font-family: Helvetica;">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="1vw" height="2vh"><path
    d="M12 20.8995L16.9497 15.9497C19.6834 13.2161 19.6834 8.78392 16.9497 6.05025C14.2161 3.31658 9.78392 3.31658 7.05025 6.05025C4.31658 8.78392 4.31658 13.2161 7.05025 15.9497L12 20.8995ZM12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364L12 23.7279ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13ZM12 15C9.79086 15 8 13.2091 8 11C8 8.79086 9.79086 7 12 7C14.2091 7 16 8.79086 16 11C16 13.2091 14.2091 15 12 15Z"></path></svg>
        &nbsp;&nbsp;&nbsp;&nbsp;ubi techpark,singapore
      </span>
      </div>
      <br><br><br><br>
      <div class="seventh-ico">
        <div v-for="(src,i) in srcArray" :key="i">
          <social-component :href="hrefArray[i]" :src="src"/>
        </div>
      </div>
      <br><br><br>
    </div>
    <el-form
              class="your-info"
             :model="dynamicValidateForm"
             ref="dynamicValidateForm"
              style="margin-top: 2rem">
      <el-input type="text"
                class="your-name"
                :placeholder="$t('message.placeholderName')"
                v-model="dynamicValidateForm.name"
                style="width: 34.5rem; font-size: 1rem"/>
<br><br>
      <el-form-item
          prop="email"
          :rules="[
      { required: true, message: '请输入邮箱地址', trigger: 'blur' },
      { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
    ]"
      >
        <el-input type="email" class="your-email" :placeholder="$t('message.placeholderEmail')" v-model="dynamicValidateForm.email" style="width: 34.5rem;font-size: 1rem"/>
        <div class="hintBox">
          <div @click="setInput(mail)" class="hintItem"  v-for="mail in emails" :key="mail">{{mail}}</div>
        </div>
      </el-form-item>
      <br>
      <el-input type="textarea"  class="your-message" :placeholder="$t('message.placeholderMessage')" v-model="dynamicValidateForm.message"
                style="width: 34.5rem;font-family: Helvetica;
                font-size: 1rem"/>
<br><br><br><br><br>
      <image-with-txt
          link="/"
          :txt=" this.$i18n.locale ==='en'? 'Get In Touch':'联系我们'"
          :name=dynamicValidateForm.name
          :email=dynamicValidateForm.email
          :message=dynamicValidateForm.message
      ></image-with-txt>
    </el-form>

  </div>
</template>

<script>
import '@/style/seventhComponent.css';
import ImageWithTxt from "@/components/UtilsComponent/ImageWithTxt.vue";
import SocialComponent from "@/components/EighthComponent/SocialComponent.vue";
import img1 from "@/assets/social1.png";
import img2 from "@/assets/social2.png";
import img3 from "@/assets/social3.png";
import img4 from "@/assets/social4.png";

export default {
  components: {SocialComponent, ImageWithTxt},
  setup() {
    return{
      img1
    }
  },
  methods:{
    setInput (mail) {
      this.dynamicValidateForm.email = mail
    }
  },
  computed: {
    emails () {
      // 如果inputEmail中没有任何东西，则不处理
      if (!this.dynamicValidateForm.email) return []
      // 考虑到用户自己要输@符号，如果@符号首次出现的位置大于等于第零个位置时，则不处理
      if (this.dynamicValidateForm.email.indexOf('@') > -1) return []
      return [
        this.dynamicValidateForm.email + '@gmail.com',
        this.dynamicValidateForm.email + '@qq.com',
        this.dynamicValidateForm.email + '@163.com',
        this.dynamicValidateForm.email + '@126.com',
        this.dynamicValidateForm.email + '@sina.com',
        this.dynamicValidateForm.email + '@263.net',
        this.dynamicValidateForm.email + '@3721.net',
        this.dynamicValidateForm.email + '@yahoo.com',
        this.dynamicValidateForm.email + '@msn.com',
        this.dynamicValidateForm.email + '@hotmail.com',
        this.dynamicValidateForm.email + '@aol.com',
        this.dynamicValidateForm.email + '@ask.com',
        this.dynamicValidateForm.email + '@live.com',
        this.dynamicValidateForm.email + '@yeah.net'
      ]
    }
  },
  data() {
    return {
      backgroundStyle: {
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      },
      dynamicValidateForm:{
        name: "",
        email: "",
        message: "",
      },
      hrefArray: [
        "",//facebook(不能使用)
        "https://x.com/ChineseKeyGoup",//twitter
        "https://www.instagram.com/chinesekey/",//Instagram
        ""//LinkedIn
      ],
      srcArray: [
        img1,
        img2,
        img3,
        img4,
      ]
    };
  },
}

</script>


<style scoped>
:deep(.el-input__inner){
  height: 3.875rem;
}
.hintBox {
  width: 100%;
  max-height: 14rem;
  overflow-y:scroll;
  overflow-x: auto;
  position: absolute;
  z-index: 999;
  background-color: #fff;
  margin-top: 2.7rem;
}
.hintItem {
  margin-top: 0.1rem;
  cursor:pointer;
  font-size: 0.8rem;
  color: #898989;
}
@media screen and (max-width: 1280px) and (min-width:1080px) {
  .hintBox {
    width: 100%;
    max-height: 14rem;
    overflow-y:scroll;
    overflow-x: auto;
    position: absolute;
    z-index: 999;
    background-color: #fff;
    margin-top: 2.9rem;
  }
}
</style>