<template>
  <div class="resolution-container" id="section3">
    <br><br><br><br>
    <div class="resolution-txt">
      {{$t('message.Our-Solutions')}}
      <br><br>
    </div>
    <div class="resolution-download">
<pc-component></pc-component>
<phone-component></phone-component>
<watch-component></watch-component>
<robot-component></robot-component>
    </div>
    <br><br><br>
  </div>
</template>
<script>
import '@/style/resolution.css';
import PcComponent from "@/components/ThirdComponent/PcComponent.vue";
import PhoneComponent from "@/components/ThirdComponent/PhoneComponent.vue";
import WatchComponent from "@/components/ThirdComponent/WatchComponent.vue";
import RobotComponent from "@/components/ThirdComponent/RobotComponent.vue";
import {EventBus} from "@/eventBus/eventBus";
export default {
  name: 'ThirdComponent',
  components: {
    PcComponent,
    PhoneComponent,
    WatchComponent,
    RobotComponent
  },
  methods:{
    handleScrollToSection(sectionId) {
      const section = document.getElementById(sectionId)
      if (section) {
        section.scrollIntoView({behavior: 'smooth'});
      }
    }
  },
  mounted() {
    EventBus.$on('scrollToSection', this.handleScrollToSection);
  },
  beforeDestroy() {
    EventBus.$off('scrollToSection', this.handleScrollToSection);
  },
}
</script>
<style scoped>

</style>