<template>
<div class="global">
  <el-dropdown :hide-on-click="false" @command="handleCommand">
  <span class="el-dropdown-link">
<!--    <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <span v-if="this.$i18n.locale==='zh' " class="language-show">中文(简)&nbsp;&nbsp;&nbsp;</span>
      <span v-if="this.$i18n.locale==='en' " class="language-show">English&nbsp;&nbsp;&nbsp;</span>
      <span v-if="this.$i18n.locale==='zh_tw' " class="language-show">中文(繁)&nbsp;&nbsp;&nbsp;</span>
    <img class="global-img" src="@/assets/globe.png" alt="">
    <!--     下拉-->
  </span>
    <el-dropdown-menu slot="dropdown" >
      <el-dropdown-item command="zh">中文(简)</el-dropdown-item>
      <el-dropdown-item command="en">English</el-dropdown-item>
      <el-dropdown-item command="zh_tw">中文(繁)</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</div>
</template>
<script>
export default {
  methods:{
    handleCommand(command){
      this.$i18n.locale = command
    }
  }
}
</script>

<style scoped>
.el-dropdown-menu__item {
  font-family: Helvetica;
}
el-dropdown-item{
font-family: Helvetica;
}
.language-show{
font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
}
.global{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.global-img{
 width: 1.25rem;
  height: 1.25rem;
}
.el-dropdown-link{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
el-dropdown-menu{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
el-dropdown{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>